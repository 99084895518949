import { Link } from "react-router-dom";
import ClubList from "../components/ClubList";
import isMobile from "../tools/is-mobile.tool";
import { useEffect, useState } from "react";
import Modal, { ModalButton } from "../components/Modal";
import Markdown from "react-markdown";
import getMarkdownFileService from "../services/get-markdown-file";
import Informations from "../components/Informations";

const nailClubCards = [
  {
    id: 0,
    description: 'Para iniciar sua jornada de autoconfiança e beleza',
    link: 'https://loja.infinitepay.io/clair-de-lune/cbu0001-clube-lua-nova',
    name: 'Lua Nova',
    price: '64,90',
    services: [
      '4 serviços de manicure mensais',
      'Preços exclusivos em outros serviços',
      'Renovação mensal',
    ],
  },
  {
    id: 1,
    description: 'Deixe sua beleza brilhar com uma rotina de cuidados exclusivos',
    link: 'https://loja.infinitepay.io/clair-de-lune/clu0002-clube-luz-do-encanto',
    name: 'Luz do Encanto',
    price: '99,90',
    services: [
      '2 serviços de manicure mensais',
      '2 serviços de pé e mão mensais',
      'Preços exclusivos em outros serviços',
      'Renovação mensal',
    ],
  },
  {
    id: 2,
    description: 'Assim como Cinderela tinha seu sapatinho de cristal, seus pés também merecem brilhar',
    link: 'https://loja.infinitepay.io/clair-de-lune/hfq3230-clube-lua-de-cristal',
    name: 'Lua de Cristal',
    price: '99,90',
    services: [
      '3 serviços de pedicure mensais',
      '1 spa dos pés mensal',
      'Preços exclusivos em outros serviços',
      'Renovação mensal',
    ],
  },
  {
    id: 3,
    description: 'Liberdade para viver suas melhores fases',
    link: 'https://loja.infinitepay.io/clair-de-lune/clu0003-clube-mulher-de-fases',
    name: 'Mulher de Fases',
    price: '139,90',
    services: [
      '4 serviços de pé e mão mensais',
      'Preços exclusivos em outros serviços',
      'Renovação mensal',
    ],
  },
];

type ModalData = {
  isVisible: boolean;
  text: string;
  onClose: () => void;
  buttons?: ModalButton[];
}

const initialModalData = { isVisible: false, text: '', onClose: () => {} };

export default function Home() {
  const [showChat, setShowChat] = useState(false);
  const [showChatText, setShowChatText] = useState(true);
  const [modalData, setModalData] = useState<ModalData>(initialModalData);
  const [articles, setArticles] = useState('');

  async function getArticles() {
    const articlesData = await getMarkdownFileService('./articles/index.md');
    setArticles(articlesData);
  }

  useEffect(() => {
    getArticles();

    const chat = setTimeout(() => {
      setShowChat(true);
    }, 5_000);

    const chatText = setTimeout(() => {
      setShowChatText(false);
    }, 15_000);

    return () => {
      clearTimeout(chat);
      clearTimeout(chatText);
    }
  }, []);

  function resetModalData() {
    setModalData(initialModalData)
  }

  function setModalWithHairClub1() {
    setModalData({
      text: 'Você possui mega hair com 200g ou mais?',
      isVisible: true,
      onClose: resetModalData,
      buttons: [
        {
          id: 0,
          text: 'Sim',
          action: () => {
            window.location.href = "https://loja.infinitepay.io/clair-de-lune/spy9970-clube-mega-hair-crescente-poderosa";
            resetModalData();
          }
        },
        {
          id: 1,
          text: 'Não',
          action: () => {
            window.location.href = "https://loja.infinitepay.io/clair-de-lune/clc0001-clube-crescente-poderosa";
            resetModalData();
          }
        }
      ],
    })
  }

  function setModalWithHairClub2() {
    setModalData({
      text: 'Você possui mega hair com 200g ou mais?',
      isVisible: true,
      onClose: resetModalData,
      buttons: [
        {
          id: 0,
          text: 'Sim',
          action: () => {
            window.location.href = "https://loja.infinitepay.io/clair-de-lune/vte4370-clube-mega-hair-cheia-de-forca";
            resetModalData();
          }
        },
        {
          id: 1,
          text: 'Não',
          action: () => {
            window.location.href = "https://loja.infinitepay.io/clair-de-lune/clc0002-clube-cheia-de-forca";
            resetModalData();
          }
        }
      ],
    })
  }

  const hairClubCards = [
    {
      id: 0,
      additionalText: 'a partir de',
      description: 'Hidrate e garanta cabelos brilhosos semanalmente',
      onClick: setModalWithHairClub1,
      name: 'Crescente Poderosa',
      price: '159,90',
      priceExceptions: 'R$209,90 para clientes com mega hair a partir de 200g',
      services: [
        '4 serviços de hidratação + escova',
        'Preços exclusivos em outros serviços',
        'Renovação mensal'
      ],
    },
    {
      id: 1,
      additionalText: 'a partir de',
      description: 'Desperte o máximo potencial da força e vitalidade da beleza de seus cabelos',
      onClick: setModalWithHairClub2,
      name: 'Cheia de Força',
      price: '259,90',
      priceExceptions: 'R$329,90 para clientes com mega hair a partir de 200g',
      services: [
        '4 tratamentos BRAÉ / Wella + escova mensais',
        'Preços exclusivos em outros serviços',
        'Renovação mensal'
      ],
    },
  ];

  return (
    <div>
      <div className="home-banner" style={{ backgroundImage: `url('./assets/background.jpg')` }}>
        <div>
          <span>Primeiro salão de beleza<br /> por assinatura de Pedra de Guaratiba!</span>
        </div>
      </div>
      <div className="home-schedule">
        {isMobile() ? (
          <a href="https://www.trinks.com/clair-de-lune/" className="home-schedule">
            Agende online agora mesmo
          </a>
        ) : (
          <Link to="/agendar" className="home-schedule">
            Agende online agora mesmo
          </Link>
        )}
      </div>
      <div id="clubes" className="home-club-container" style={{ backgroundImage: `url('./assets/background-club-nails.jpg')` }}>
        <div className="home-club-title">Clubes para unhas 💅🏻</div>
        <div className="home-club-description-container">
          <span className="home-club-description">
            Garanta que suas unhas estarão impecáveis
            a todo momento com nossos clubes
            que te dão direito a manicure/pedicure
            semanalmente com baixo custo!
          </span>
        </div>
        <ClubList cards={nailClubCards} />
      </div>
      <div className="home-club-container"  style={{ backgroundImage: `url('./assets/background-club-hair.jpg')` }}>
        <div className="home-club-title">Clubes para cabelos 💇‍♀️</div>
        <div className="home-club-description-container">
          <span className="home-club-description">
            Tenha cabelos sempre saudáveis
            com rotinas de tratamentos exclusivas
            pensadas para as suas necessidades
            que só nossos clubes podem
            proporcionar!
          </span>
        </div>
        <ClubList cards={hairClubCards} />
      </div>
      <div className="home-articles">
        <div className="home-articles-container">
          <div className="home-articles-title">Artigos</div>
          <Markdown>
            {articles}
          </Markdown>
        </div>
      </div>
      <Informations />
      {showChat && (
        <a
          href="https://wa.me/5521986841208?text=Olá!%20Vim%20pelo%20site%20e%20gostaria%20de%20tirar%20dúvidas!"
          target="_blank" rel="noreferrer"
          className="home-chat"
        >
          {showChatText && <span className="home-chat-text">Ficou com alguma dúvida? Fale conosco!</span>}
          <img src="./assets/icon-whatsapp.svg" alt="WhatsApp" />
        </a>
      )}
      {modalData.isVisible && (
        <Modal
          text={modalData.text}
          buttons={modalData.buttons}
          onClose={modalData.onClose}
        />
      )}
    </div>
  );
}
